/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Text, Title, Image, Subtitle } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-l1y9zg" name={"uvod"} style={{"paddingTop":65,"paddingBottom":532}} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/30976/2af87f1f6d5a46e19296fa4a22472e5e_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/30976/2af87f1f6d5a46e19296fa4a22472e5e_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/30976/2af87f1f6d5a46e19296fa4a22472e5e_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/30976/2af87f1f6d5a46e19296fa4a22472e5e_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/30976/2af87f1f6d5a46e19296fa4a22472e5e_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/30976/2af87f1f6d5a46e19296fa4a22472e5e_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/30976/2af87f1f6d5a46e19296fa4a22472e5e_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/30976/2af87f1f6d5a46e19296fa4a22472e5e_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="pb--60" name={"m00g605y4b"} style={{"paddingTop":47}}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Text className="text-box" content={"Pojď pracovat s námi"}>
              </Text>

              <Title className="title-box title-box--style2" style={{"marginTop":9}} content={"Kariéra\n"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2" style={{"marginTop":67}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim2 --shadow4 --left pb--50 pl--20 pr--20 pt--25" anim={"2"} style={{"maxWidth":""}}>
              
              <Image className="--left" alt={"Ikonka"} src={"https://cdn.swbpg.com/o/g/Ikonky/Kontakt/3393397d01674c7f9d1e183243e68c8f.svg"} sizes={"100vw"} style={{"maxWidth":80}} srcSet={""} svgColor={"rgba(65,117,5,1)"}>
              </Image>

              <Subtitle className="subtitle-box subtitle-box--style2 w--600" style={{"marginTop":14}} content={"Truhlář v provozu"}>
              </Subtitle>

              <Text className="text-box w--700 lh--16" style={{"maxWidth":""}} content={"Stolařství Šimčák je rodinná firma s 33 letou tradicí, důrazem na kvalitu a rodinnou atmosférou v Malenovicích pod Lysou horou.\n"}>
              </Text>

              <Text className="text-box w--700 lh--16" style={{"marginTop":52}} content={"Koho hledáme do našeho týmu?\n\n\n"}>
              </Text>

              <Text className="text-box lh--16 mt--12" content={"Do našeho rozrůstajícího se týmu hledáme zručné stolaře/truhláře pro zakázkovou výrobu."}>
              </Text>

              <Text className="text-box w--700 lh--16" style={{"marginTop":52}} content={"Co nabízíme? \n\n\n\n"}>
              </Text>

              <Text className="text-box lh--16 mt--12" content={"- Zázemí rodinné firmy\n\n\n"}>
              </Text>

              <Text className="text-box lh--16 mt--06" content={"- Příjemné pracovní prostředí\n\n\n"}>
              </Text>

              <Text className="text-box lh--16 mt--06" content={"- Práci s kvalitním nářadím a moderními technologiemi \n\n\n"}>
              </Text>

              <Text className="text-box lh--16 mt--06" content={"- Finanční ohodnocení odpovídající kvalitě odvedené práce\n\n\n"}>
              </Text>

              <Text className="text-box lh--16 mt--06" content={"- Odměny v průběhu roku \n\n\n\n"}>
              </Text>

              <Text className="text-box lh--16 mt--06" content={"- Příspěvěk na stravování\n\n\n\n"}>
              </Text>

              <Text className="text-box lh--16 mt--06" content={"- Náborový příspěvek \n\n\n\n"}>
              </Text>

              <Text className="text-box w--700 lh--16" style={{"marginTop":52}} content={"Co požadujeme?\n\n\n\n\n\n\n"}>
              </Text>

              <Text className="text-box lh--16 mt--12" content={"- Praxe v oboru výroba nábytku \n\n\n"}>
              </Text>

              <Text className="text-box lh--16 mt--06" content={"- Pracovitost, samostatnost, spolehlivost a tah na branku \n\n\n"}>
              </Text>

              <Text className="text-box lh--16 mt--06" content={"- Chuť se rozvíjet\n\n\n"}>
              </Text>

              <Text className="text-box w--700 lh--16" style={{"marginTop":52}} content={"Místo výkonu práce:\n\n\n\n\n\n\n\n"}>
              </Text>

              <Text className="text-box lh--16 mt--12" content={"Nová Ves 508, Frýdlant nad Ostravicí \n \n\n\n"}>
              </Text>

              <Text className="text-box lh--16 mt--30" content={"Informace na:\n\n\n"}>
              </Text>

              <Text className="text-box lh--16 mt--06" content={"<span style=\"color: var(--color-custom-1);\">info@simcak.net</span>"}>
              </Text>

              <Text className="text-box lh--16 mt--06" content={"Tel. číslo:  +420 773 622 080\n<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --shadow4 --left pb--50 pl--20 pr--20 pt--25" anim={"2"} style={{"maxWidth":""}}>
              
              <Image className="--left" alt={"Ikonka"} src={"https://cdn.swbpg.com/o/g/Ikonky/Kontakt/3393397d01674c7f9d1e183243e68c8f.svg"} sizes={"100vw"} style={{"maxWidth":80}} srcSet={""} svgColor={"rgba(65,117,5,1)"}>
              </Image>

              <Subtitle className="subtitle-box subtitle-box--style2 w--600" style={{"marginTop":14}} content={"Montážník v terénu \n"}>
              </Subtitle>

              <Text className="text-box w--700 lh--16" style={{"maxWidth":""}} content={"Stolařství Šimčák je rodinná firma s 33 letou tradicí, důrazem na kvalitu a rodinnou atmosférou v Malenovicích pod Lysou horou.\n"}>
              </Text>

              <Text className="text-box w--700 lh--16" style={{"marginTop":52}} content={"Koho hledáme do našeho týmu?\n\n\n"}>
              </Text>

              <Text className="text-box lh--16 mt--12" content={"Do našeho rozrůstajícího se týmu hledáme zručné montážníky pro zakázkovou výrobu."}>
              </Text>

              <Text className="text-box w--700 lh--16" style={{"marginTop":52}} content={"Co nabízíme? \n\n\n\n"}>
              </Text>

              <Text className="text-box lh--16 mt--12" content={"- Zázemí rodinné firmy\n\n\n"}>
              </Text>

              <Text className="text-box lh--16 mt--06" content={"- Příjemné pracovní prostředí\n\n\n"}>
              </Text>

              <Text className="text-box lh--16 mt--06" content={"- Práci s kvalitním nářadím a moderními technologiemi \n\n\n"}>
              </Text>

              <Text className="text-box lh--16 mt--06" content={"- Finanční ohodnocení odpovídající kvalitě odvedené práce\n\n\n"}>
              </Text>

              <Text className="text-box lh--16 mt--06" content={"- Odměny v průběhu roku \n\n\n\n"}>
              </Text>

              <Text className="text-box lh--16 mt--06" content={"- Příspěvěk na stravování\n\n\n\n"}>
              </Text>

              <Text className="text-box lh--16 mt--06" content={"- Náborový příspěvek \n\n\n\n"}>
              </Text>

              <Text className="text-box w--700 lh--16" style={{"marginTop":52}} content={"Co požadujeme?\n\n\n\n\n\n\n"}>
              </Text>

              <Text className="text-box lh--16 mt--12" content={"- Praxe s montáží nábytku, případně jeho výrobou  \n \n\n\n"}>
              </Text>

              <Text className="text-box lh--16 mt--06" content={"- Příjemné vystupování \n \n\n\n"}>
              </Text>

              <Text className="text-box lh--16 mt--06" content={"- Řidičský průkaz skupiny B\n \n\n\n"}>
              </Text>

              <Text className="text-box lh--16 mt--06" content={"- Pracovitost, spolehlivost a tah na branku \n\n\n\n"}>
              </Text>

              <Text className="text-box lh--16 mt--06" content={"- A chuť se rozvíjet\n \n\n\n\n"}>
              </Text>

              <Text className="text-box w--700 lh--16" style={{"marginTop":52}} content={"Místo výkonu práce:\n\n\n\n\n\n\n\n"}>
              </Text>

              <Text className="text-box lh--16 mt--12" content={"Nová Ves 508, Frýdlant nad Ostravicí \n \n\n\n"}>
              </Text>

              <Text className="text-box lh--16 mt--30" content={"Informace na:\n\n\n"}>
              </Text>

              <Text className="text-box lh--16 mt--06" content={"<span style=\"color: var(--color-custom-1);\">info@simcak.net</span>"}>
              </Text>

              <Text className="text-box lh--16 mt--06" content={"Tel. číslo:  +420 773 622 080\n<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" name={"mf0yn4h9ohk"} style={{"paddingTop":75,"backgroundColor":"#faf8f8"}}>
          
          <ColumnWrap className="column__flex --center el--2" style={{"maxWidth":890}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5" anim={"5"} style={{"maxWidth":480}}>
              
              <Image alt={"ikonka"} src={"https://cdn.swbpg.com/o/g/Ikonky/Kontakt/6b72aab19c4f4d1ca38a5ca397b2ec1c.svg"} sizes={"100vw"} style={{"maxWidth":90}} srcSet={""} svgColor={"rgba(65,117,5,1)"}>
              </Image>

              <Text className="text-box" style={{"marginTop":10}} content={"Životopisy prosím zasílejte na:"}>
              </Text>

              <Text className="text-box fs--18 w--700 lh--18" style={{"marginTop":10}} content={"info@simcak.net"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4" anim={"4"} style={{"maxWidth":480}}>
              
              <Image alt={"ikonka"} src={"https://cdn.swbpg.com/o/g/Ikonky/Kontakt/965c3e40d27d46d385ed085e1b11d2fa.svg"} sizes={"100vw"} style={{"maxWidth":90}} srcSet={""} svgColor={"rgba(65,117,5,1)"}>
              </Image>

              <Text className="text-box" style={{"marginTop":10}} content={"V případě dotazu volejte na:"}>
              </Text>

              <Text className="text-box fs--18 w--700 lh--18" style={{"marginTop":10}} content={"773 622 &nbsp;080"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" name={"sluzby"} style={{"paddingTop":73}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s2" anim={"2"} animS={"2"}>
              
              <Title className="title-box title-box--style2" content={"Proč pracovat právě u nás\n"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":698}} content={"Pomáháme vytvářet nábytek a interiéry, ve kterých se bude lidem lépe pracovat a žít. Neustále rosteme a máme jasný cíl: udělat z dobré firmy skvělou."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 --center el--3 mb--10 mt--40 pr--08" anim={"2"} animS={"2"} style={{"maxWidth":1000}} columns={"3"}>
            
            <ColumnWrapper className="js-anim  --anim2" anim={"2"}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/Finance/finanfce-5.svg"} sizes={"100vw"} style={{"maxWidth":96}} srcSet={""} svgColor={"rgba(65,117,5,1)"}>
              </Image>

              <Subtitle className="subtitle-box w--600" content={"Stabilita\n"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2" anim={"2"}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/Kontakt/ec56f61a8d3f46ea995f227e5056cd0f.svg"} sizes={"100vw"} style={{"maxWidth":96}} srcSet={""} svgColor={"rgba(65,117,5,1)"}>
              </Image>

              <Subtitle className="subtitle-box w--600" content={"Kvalita\n"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2" anim={"2"}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/Finance/finanfce-4.svg"} sizes={"100vw"} style={{"maxWidth":96}} srcSet={""} svgColor={"rgba(65,117,5,1)"}>
              </Image>

              <Subtitle className="subtitle-box w--600" content={"Jistota\n"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--08 pt--08" name={"paticka"} style={{"backgroundColor":"rgba(65,117,5,1)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 el--3" anim={"2"} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box fs--20" content={"<span style=\"color: rgb(255, 255, 255);\">prace.simcak.net</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}